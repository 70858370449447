<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          ref="searchWrapper"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">
            {{ $t("searchModule.Query_Table") }}
          </div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item
                :label="$t('searchModule.plate_number')"
                prop="plateNumber"
              >
                <el-autocomplete
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model.trim="plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item
                :label="$t('searchModule.Car_owner_name')"
                prop="carOwner"
              >
                <el-input
                  v-model.trim="formInline.carOwner"
                  placeholder="请输入车主姓名"
                  size="12"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_name')">
                <my-component
                  ref="parkInput"
                  :areaIds="
                    formInline.streetId
                      ? formInline.streetId
                      : formInline.areaId
                  "
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  slaveRelations="0,2"
                ></my-component>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                v-if="$route.meta.authority.tabs.specialAudit.button.query"
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t("button.search") }}</el-button
              >
              <el-button
                icon="el-icon-refresh-right"
                @click="resetSearchForm"
                >{{ $t("button.reset") }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('searchModule.plate_number')"
            align="center"
            prop="plateNumber"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('searchModule.License_plate_color')"
            align="center"
            prop="plateColorName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('searchModule.Car_owner_name')"
            align="center"
            prop="carOwner"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('searchModule.phone_number')"
            align="center"
            prop="mobile"
            width="120"
          ></el-table-column>
          <el-table-column
            :label="$t('searchModule.classification')"
            align="center"
            prop="specialPlateTypeName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('searchModule.Merchant_Name')"
            align="center"
            prop="operationName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('searchModule.Road_name')"
            show-overflow-tooltip
            align="center"
            width="105"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.scope == 2">{{ scope.row.parkName }}</span>
              <span v-if="scope.row.scope == 1">{{
                $t("list.All_roads")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('searchModule.Vehicle_status')"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span v-if="scope.row.carStatus === 0"
                ><span style="color: red">{{
                  $t("list.Stop_using")
                }}</span></span
              >
              <span v-if="scope.row.carStatus === 1">{{
                $t("list.Enable")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('searchModule.applicant')"
            align="center"
            prop="creatorName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('list.Application_Time')"
            align="center"
            prop="createdTime"
            width="170"
          ></el-table-column>
          <el-table-column
            :label="$t('list.Approval_Status')"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status === 0">{{
                $t("list.Pending_approval")
              }}</span>
              <span v-if="scope.row.status === 1">{{ $t("list.Passed") }}</span>
              <span v-if="scope.row.status === 2">{{
                $t("list.Rejected")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="$route.meta.authority.tabs.specialAudit.button.audit"
            :label="$t('list.operation')"
            fixed="right"
            align="center"
            width="80"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                style="padding: 0"
                @click="audit(scope.row)"
                >审核</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <audit
      ref="audit"
      :formData="formData"
      :dialogCheckVisible="dialogCheckVisible"
      :systemLogData="systemLogData"
      @dialogCheckClose="dialogCheckClose"
      :systemPageNum="systemPageNum"
      :systemPageSize="systemPageSize"
      :systemTotal="systemTotal"
      @handleSystemCurrentChange="handleSystemCurrentChange"
      @auditConfirm="auditConfirm"
    />
  </div>
</template>

<script>
import Audit from "./audit";
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  name: "whiteListAudit",
  components: {
    Audit,
    myComponent,
  },
  data() {
    return {
      tenantList: [],
      plateNumber: "",
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      header: {
        "Session-Id": sessionStorage.token,
      },
      tableData: [],
      formInline: {
        carOwner: "",
        carId: "",
        operationId: "",
        parkId: "",
      },
      formData: {},
      dialogCheckVisible: false,
      systemLogData: [],
      systemTotal: 0,
      systemPageSize: 5,
      systemPageNum: 1,
      specialPlateId: "",
      specialPlateApplyId: "",
    };
  },
  watch: {
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.carId = "";
      }
    },
  },
  mounted() {
    this.getTenantList();
    this.searchData();
  },
  methods: {
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    handleSelect(item) {
      this.formInline.carId = item.carId;
      this.pageNum = 1;
    },
    querySearchAsync(queryString, cb) {
      this.formInline.carId = "";
      queryString = queryString.replace(/\s/g, "");
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      this.$axios
        .get("/acb/2.0/parkRecord/plateNumber/list", {
          data: {
            part: queryString.toUpperCase(),
            size: 100,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        });
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.loading = true;
      const opt = {
        url: "/acb/2.0/specialPlateApply/list",
        method: "get",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          plateType: 0,
          ...this.formInline,
          status: 0,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = parseInt(res.value.total);
          }
        },
        fail: (res) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    // 清空
    resetSearchForm() {
      this.formInline = {
        carOwner: "",
        carId: "",
        operationId: "",
      };
      this.$refs.parkInput.setValue();
      this.plateNumber = "";
      this.searchData();
    },
    // 关闭查看框
    dialogCheckClose() {
      this.$refs.audit.resetForm();
      this.systemLogData = [];
      this.dialogCheckVisible = false;
    },
    // 审核弹窗
    audit(row) {
      this.dialogCheckVisible = true;
      const opt = {
        url: "/acb/2.0/specialPlateApply/getById",
        method: "get",
        data: {
          specialPlateApplyId: row.specialPlateApplyId,
          plateType: 0,
        },
        success: (res) => {
          this.specialPlateId = res.value.specialPlateId;
          this.specialPlateApplyId = res.value.specialPlateApplyId;
          this.getSystemLogData(this.specialPlateId, this.specialPlateApplyId);
          const commonKey = res.value.resourcesId;
          const option = {
            url: "/acb/2.0/specialPlateApply/getImage",
            method: "get",
            data: {
              commonKey,
            },
            success: (response) => {
              const fileList = JSON.parse(JSON.stringify(response.value));
              const newData = Object.assign({ fileList }, res.value);
              this.formData = newData;
            },
          };
          this.$request(option);
        },
      };
      this.$request(opt);
    },
    // 审核
    auditConfirm(postData) {
      const opt = {
        url: "/acb/2.0/specialPlateApply/audit",
        method: "post",
        data: postData,
        success: (res) => {
          this.dialogCheckClose();
          this.$alert("操作成功");
          this.searchData();
        },
      };
      this.$request(opt);
    },
    // 获取系统日志信息
    getSystemLogData(specialPlateId, specialPlateApplyId) {
      const opt = {
        url: "/acb/2.0/specialPlateApply/logList",
        method: "get",
        data: {
          pageNum: this.systemPageNum,
          pageSize: this.systemPageSize,
          specialPlateId,
          specialPlateApplyId,
        },
        success: (res) => {
          this.systemLogData = res.value.list;
          this.systemTotal = parseInt(res.value.total);
        },
      };
      this.$request(opt);
    },
    // 分页
    handleSystemCurrentChange(val) {
      this.systemPageNum = val;
      this.getSystemLogData(this.specialPlateId, this.specialPlateApplyId);
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // padding 22px 22px 0
    // margin-bottom 20px
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.upload-demo {
  display: inline-block;
}
</style>
